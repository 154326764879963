<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card no-body>
        <div class="m-2">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <div class="row">
                <div class="col-md-3">
                </div>
              </div>
            </div>
            <AdsAccountAdd
              :manage="TokenService.getPermissions('add_ads_account')"
              @emitStatusAdded="getAdsAccount"
            ></AdsAccountAdd>
          </div>
        </div>
        <b-table-simple :key="refreshScreen" hover caption-top responsive class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
                <b-th>Advertiser Name</b-th>
                <b-th>Contact Email</b-th>
                <b-th>Contact Phone</b-th>
                <b-th>Website URL</b-th>
                <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <tbody v-if="get_ads.total > 0">
            <tr v-for="item in get_ads.data" :key="item._id">
              <td>{{ item.advertiser_name }}</td>
              <td>{{ item.contact_email }}</td>
              <td>{{ item.contact_phone }}</td>
              <td>{{ item.website_url }}</td>
              <td
                  v-if="TokenService.getPermissions('update_ads_account') || TokenService.getPermissions('delete_ads_account')"
                >
                <div class="d-flex align-items-center">
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item 
                      v-if="TokenService.getPermissions('update_ads_account')"
                      @click="editBtn(item)">
                      <div>
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </div>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item 
                      v-if="TokenService.getPermissions('delete_ads_account')"
                      @click="deleteBtn(item._id)">
                      <div>
                        <b-spinner v-if="loading" small class="mr-1" />
                        <feather-icon v-else icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </div>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10" class="text-center">
                <div class="p-2">No Record Found</div>
              </td>
            </tr>
          </tbody>
        </b-table-simple>
        <div class="mx-2 mb-1">
          <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="dataTables_info mt-1">Showing {{get_ads.from}} to {{get_ads.to}} of {{get_ads.total}} entries</div>
            <pagination 
              class="mt-1 mb-0"
              :data="get_ads"
              :limit=5
              @pagination-change-page="getAdsAccount"
            ></pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>

    <AdsAccountEdit
      :manage="TokenService.getPermissions('update_ads_account')"
      @emitStatusUpdated="getAdsAccount"
      :edit_ads_account_data="edit_ads_account_data"
    ></AdsAccountEdit>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BOverlay,
  BLink,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTableSimple,
  BThead,
  BFormCheckbox,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AdsAccountAdd from "./AdsAccountAdd.vue";
import AdsAccountEdit from "./AdsAccountEdit.vue";
import axios from "axios";
import * as _ from "lodash";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Helpers from '../../Helpers';
import TokenService from "../../TokenService";
import draggable from "vuedraggable";

export default {
  components: {
    AdsAccountAdd,
    AdsAccountEdit,
    BOverlay,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BFormCheckbox,
    BThead,
    BTr,
    BTh,
    ToastificationContent,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    vSelect,
    BSpinner,
    draggable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      drag: false,
      classId: "",
      show: false,
      refreshScreen: 0,
      alertData: "",
      errorMsg: "",
      loading: false,

      record_filters: {},
      get_ads: {},
      get_advertisers: {},

      edit_ads_account_data: {},
      TokenService,
    };
  },
  created() {
    if(!TokenService.getPermissions("view_ads_account")) {
      this.$router.push("/error-404");
    } else {
      this.getAdsAccount();
    }
  },
  methods: {
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    getAdsAccount: function (page = 1) {
      var url = process.env.VUE_APP_API_URL + `/ads-account?page=${page}`;
      const payload = this.record_filters
      this.show = true;
      axios
        .post(url, payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.get_ads = res.data.adsAccount;
          this.show = false;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    editBtn(data) {
      this.edit_ads_account_data = data;
      this.$bvModal.show("edit-ads-account-modal");
    },
    filterDropdown() {
      this.getAdsAccount();
    },
    deleteBtn(id) {
      Swal.fire({
        title: "Are you sure?",
        text: `Do You want to delete this status!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(id)
        }
      });
    },
    async delete(id) {
      this.loading = true;
      try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/ads-account/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        });

        this.alertData = resp.data.message;
        this.showToast("success");
        this.loading = false;
        this.getAdsManagement();
      } catch (error) {
        console.log('error=',error)
        this.errorMsg = Helpers.catchFunction(error);
        this.alertData = this.errorMsg;
        this.showToast("danger");
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  .add-catrgory-button {
    margin-top: 10px;
  }
</style>
<style>
  .vs__open-indicator {
    fill: #9d9d9d !important;
  }
</style>
 